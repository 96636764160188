import React, {useEffect, useRef, useState} from "react";
import {widget} from "@assets/charting_library";
import datafeed from "./datafeed";
import useStyles from "@helpers/theme/useStyles";
import {useSelector} from "react-redux";
import {BidNotification} from "@components/library";
import "./index.sass";
import moment from "moment-timezone";
import {convertToUserTimezone, getUserTimezone} from "@helpers/timezone";

export const MyTradingViewWidget = () => {
  const chartContainerRef = useRef();
  const styles = useStyles();
  const [myWidget, setMyWidget] = useState(null);
  const {locale} = useSelector((state) => state.localization);
  const [positionLines, setPositionLines] = useState([]);

  const {
    theme: {theme},
    trading: {pair},
    bids: {openedHistory},
  } = useSelector((state) => state);

  const defaultProps = {
    symbol: pair.slug,
    interval: "1",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    toolbar_bg: "#f1f3f6",
    backgroundColor: styles.widget.bgWidget,
    gridColor: styles.widget.grid,
    supports_marks: true,
  };

  const clearPositionLines = () => {
    positionLines.forEach((line) => {
      line.remove();
    });
    setPositionLines([]);
  };

  const changeSymbol = async () => {
    await myWidget.activeChart().setSymbol(pair.slug, () => {
      showCurrentMarks();
      myWidget.activeChart().resetData();
      console.log('resetData')
    });
  };

  const showCurrentMarks = () => {
    if (!myWidget) return;

    myWidget.onChartReady(() => {
      clearPositionLines();

      const activeSymbolBids = openedHistory.filter(
        (el) => el.pair_name === pair.name
      );

      activeSymbolBids.forEach((el) => {
        drawMark(convertToUserTimezone(el.created_at), convertToUserTimezone(el.closing_at), el.opening_price, el.type, el.contribution);
      });
    });
  };

  const drawMark = (open, close, price, type, amount) => {
    const color = type === "up" ? "#00AD64" : "#FE3D31";
    const lineColor = type === "up" ? "#6bc7a0" : "#ffada8";

    const positionLine = myWidget.activeChart().createPositionLine()
      .setBodyTextColor("#FFF")
      .setBodyBorderColor(color)
      .setBodyBackgroundColor(color)
      .setQuantity("")
      .setLineColor(lineColor)
      .setText(`${type === "up" ? "▲" : "▼"}${amount}$`)
      .setPrice(price)
      .setExtendLeft(false)
      .setLineStyle(0)
      .setLineLength(25);

    setPositionLines((prevLines) => [...prevLines, positionLine]);
  };

  useEffect(() => {
    if (myWidget) {
      showCurrentMarks();
    }
  }, [openedHistory.length, myWidget]);

  useEffect(() => {
    if (myWidget) {
      changeSymbol();
    }
  }, [pair]);

  useEffect(() => {
    const offset = moment.tz(getUserTimezone()).utcOffset() / 60;
    const savedInterval = localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution') || defaultProps.interval;

    const widgetOptions = {
      timezone: getUserTimezone(),
      symbol: defaultProps.symbol,
      datafeed: datafeed,
      interval: savedInterval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      locale,
      disabled_features: [
        // "use_localstorage_for_settings",
        // "header_widget",
        "header_symbol_search",
        "header_compare",
        "header_styles",
        "header_indicators",
        "header_chart_type",
        "header_study_templates",
        "header_undo_redo",
        // "header_save_load",
        "header_quick_search",
        "header_fullscreen_button",
        "header_saveload",
        "header_screenshot",
        "header_settings",
        "left_toolbar",
        "timeframes_toolbar",
        "legend_widget",
      ],
      enabled_features: ["study_templates", "use_localstorage_for_settings", "saved_data"],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: theme === "white" ? "light" : "dark",
      fullWidth: true,
      backgroundColor: styles.widget.bgWidget,
      gridColor: styles.widget.grid,
      toolbar_bg: "#f1f3f6",
      custom_timezones: [{
        id: getUserTimezone(),
        alias: `Etc/GMT${offset > 0 ? '-' : '+'}${Math.abs(offset)}`,
        title: getUserTimezone().replace('_', ' '),
      }],
      saveInterval: true,
      autoSave: true,
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(async () => {
      setMyWidget(tvWidget);
    });

    return () => {
      tvWidget.remove();
    };
  }, []);

  useEffect(() => {
    if (myWidget) {
      myWidget.changeTheme(theme === "white" ? "light" : "dark", {
        backgroundColor: styles.widget.bgWidget,
        gridColor: styles.widget.grid,
      });
    }
  }, [theme]);

  return (
    <div className={"TVChartContainer tradingview-widget"}>
      <div
        className="tradingview-widget__wrapper"
        ref={chartContainerRef}
      ></div>
      <BidNotification/>
    </div>
  );
};

export default MyTradingViewWidget;
